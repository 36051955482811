import React from 'react'
import Navbar from '../../Component/Navbar/Navbar'
import HeroImg from '../../Component/HeroImg/HeroImg'

const Home = () => {
  return (
    <div>
       
            <HeroImg></HeroImg>
        {/* <Navbar></Navbar> */}
    </div>
  )
}

export default Home