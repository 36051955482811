import React from 'react'
import Form from '../../Component/Form/Form.js'
import HeroImg2 from '../../Component/HeroImg2/HeroImg2.js'
const Contact = () => {
  return (
    <div>
     <HeroImg2 heading="CONTACT." text="Lets have a chat" />
         <Form />
    </div>
  )
}

export default Contact